import React, { useContext } from 'react'
import BarLateral from '../Components/BarLateral/BarLateral'
import Header from '../Components/Header/Header'
import { ContextBar } from '../Context/BarContext'
import Mostradores from '../Mostradores/Mostradores'

function ViewMostradores() {
    const { toggle } = useContext(ContextBar)
    return (
      <div style={{ display: "flex" }}>
        <BarLateral />
        <div style={toggle ? { width: "80%", transition: "1s" } : { width: "100%", transition: "1s" }}>
          <Header />
          <Mostradores></Mostradores>
        </div>
      </div>
    )
}

export default ViewMostradores