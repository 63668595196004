import React, { useContext } from 'react'
import ListAserores from '../Components/Asesores/ListAserores'
import BarLateral from '../Components/BarLateral/BarLateral'
import Header from '../Components/Header/Header'
import { ContextBar } from '../Context/BarContext'

function ViewAdvisors() {
    const { toggle } = useContext(ContextBar)

    return (
        <div style={{ display: "flex" }}>
            <BarLateral />
            <div style={toggle ? { width: "80%", height: "100vh", transition: "1s", overflowY: "scroll" } : { width: "100%",height: "100vh", transition: "1s", overflowY: "scroll" }}>
                <Header />
               <ListAserores></ListAserores>
            </div>
        </div>
    )
}

export default ViewAdvisors