import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import Ruleta from "./Views/Ruleta";
import Home from "./Views/Home";
import Ruletas from "./Views/Ruletas";
import ViewProductos from "./Views/ViewProductos";
import ViewCreateProducto from "./Views/ViewCreateProducto";
import ViewCreateRuleta from "./Views/ViewCreateRuleta";
import React, { useContext, useEffect } from "react";
import Login from "./Views/Login";
import { ContextAuth } from "./Context/AuthContext";
import Error404 from "./Views/Error404";
import logoCorteva from "./img/LOGOS CORTEVA_Mesa de trabajo 1.png";
import logoGanaderia from "./img/LOGO SUPER GANADERIA_BLANCO.png";
import Loading from "./Components/Loading/Loading";
import ViewCreateAsesor from "./Views/ViewCreateAsesor";
import ViewAdvisors from "./Views/ViewAdvisors";
import ViewMostradores from "./Views/ViewMostradores";
import ViewsDashboardRuletas from "./Views/ViewsDashboardRuletas";

function App() {
  const { isAuthenticate } = useContext(ContextAuth);

  return (
    <div className="app">
      {isAuthenticate === undefined && <Loading></Loading>}
      <Router>
        <Routes>
          <Route
            index
            path="/"
            element={isAuthenticate ? <Home /> : <Login />}
          />
          <Route index path="/login" element={!isAuthenticate && <Login />} />
          <Route
            index
            path="ruletas"
            element={isAuthenticate ? <Ruletas /> : <Login />}
          />

          <Route
            path="productos"
            element={isAuthenticate ? <ViewProductos /> : <Login />}
          />
          <Route
            index
            path="productos/crear"
            element={isAuthenticate ? <ViewCreateProducto /> : <Login />}
          />
          <Route
            index
            path="ruletas/crear"
            element={isAuthenticate ? <ViewCreateRuleta /> : <Login />}
          />
          <Route
            index
            path="asesores/crear"
            element={isAuthenticate ? <ViewCreateAsesor /> : <Login />}
          />
          <Route
            index
            path="/asesores"
            element={isAuthenticate ? <ViewAdvisors /> : <Login />}
          />
          <Route
            index
            path="/mostradores"
            element={isAuthenticate ? <ViewMostradores /> : <Login />}
          />
          <Route index path="/ruleta&:nombreruleta=:id&:nombrezona=:idzona" element={<Ruleta />} />
          <Route index path="/dashboard-ruletas" element={<ViewsDashboardRuletas />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Router>

      <div className="logos">
        <img src={logoCorteva} className="logo-bajo-corteva"></img>
        <img src={logoGanaderia} className="logo-bajo"></img>
      </div>
    </div>
  );
}

export default App;
