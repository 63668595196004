import axios from "axios";
import React, { useState } from "react";
import { post } from "../../PeticionesHTTP/Peticiones";
import "./Productos.css";

function CreateProduct() {
  const [imageUpload, setImageUpload] = useState();
  const [valueProgress, setValueProgress] = useState(0);
  const [progressIsOpen, setProgressIsOpen] = useState(false);
  const [dataProduct, setdataProduct] = useState({
    name: "",
    cantidad_total: "",
    cantidad_lista: "",
    descripcion: "",
    imagen: "",
  });

  const handleChange = (e) => {
    setdataProduct({
      ...dataProduct,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeImage = async (e) => {
    setProgressIsOpen(true);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const respuesta = await axios.post(
      "https://api-ruleta.equipogctrade.com/api/upload",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress(e) {
          setValueProgress((e.loaded * 100) / e.total);
        },
      }
    )
    setImageUpload(respuesta.data.mensaje);
    dataProduct.imagen = "https://api-ruleta.equipogctrade.com/" + respuesta.data.mensaje;
    if(respuesta.status === 200) setProgressIsOpen(false)
    console.log(respuesta);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_API}/producto`;
    const body = {
      name: dataProduct.name,
      cantidad_total: dataProduct.cantidad_total,
      cantidad_lista: dataProduct.cantidad_total,
      descripcion: dataProduct.descripcion,
      imagen: dataProduct.imagen,
    };

    post(url, body);
    e.target.reset();
    setImageUpload("")
  };

  return (
    <div>
      <form className="Form-create-product" onSubmit={handleSubmit}>
        <h1>Crear producto</h1>
        {imageUpload && (
          <div className="product-image">
            <img src={"https://api-ruleta.equipogctrade.com/" + imageUpload}></img>
          </div>
        )}
        {progressIsOpen && (
          <progress value={valueProgress} max={"100"}></progress>
        )}
        <input
          name="file"
          type="file"
          placeholder="Nombre del producto"
          onChange={handleChangeImage}
        ></input>
        <input
          name="name"
          type="text"
          placeholder="Nombre del producto"
          onChange={(e) => handleChange(e)}
        ></input>
        <input
          name="cantidad_total"
          type={"number"}
          placeholder="Cantidad"
          onChange={(e) => handleChange(e)}
        ></input>
        <textarea
          name="descripcion"
          type={"text"}
          placeholder="Descripcion"
          onChange={(e) => handleChange(e)}
        ></textarea>
        <input type={"submit"}></input>
      </form>
    </div>
  );
}

export default CreateProduct;
