import React, { useContext } from 'react'
import BarLateral from "../Components/BarLateral/BarLateral"
import LightBox from '../Components/LightBox/LightBox'
import ListaRuletas from '../Components/Ruleta/ListaRuletas'
import { ContextBar } from '../Context/BarContext'
import Header from "./../Components/Header/Header"

function Ruletas() {

    const { toggle } = useContext(ContextBar)

    return (
        <div style={{display: "flex"}}>
            <BarLateral />
            <div style={toggle ? { width: "80%", transition: "1s" } : { width: "100%", transition: "1s" }}>
                <Header />
                <ListaRuletas/>
                {/* <LightBox></LightBox> */}
            </div>
        </div>
    )
}

export default Ruletas