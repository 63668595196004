export const get = async (url) => {
  const response = await fetch(url);
  const result = await response.json();

  return result;
};

export const getProducts = async (url) => {
  let result;
  await fetch(url)
    .then((res) => res.json())
    .then((resultado) => {
      result = resultado;
      localStorage.setItem("get_products", JSON.stringify(resultado));
    })
    .catch(() => {
      const dataStorage = localStorage.getItem("get_products");
      if (dataStorage !== undefined) {
        result = JSON.parse(dataStorage);
      }
    });

  return result;
};

export const postWithResult = async (url, body) => {
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const result = await response.json();
  return result;
};
export const postAuth = async (url, body, token) => {
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  const result = await response.json();
  return result;
};

export const post = async (url, body) => {
  console.log(url);
  await fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  }).then(() => console.log("se creo satisfactoriamente"));
};

export const eliminar = async (url) => {
  console.log(url);
  await fetch(url, {
    method: "DELETE",
  }).then(() => console.log("se elimino"));
};

export const update = async (url, body) => {
  console.log(url);
  await fetch(url, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(() => console.log("se actualizo"))
    .catch(() => {
      const get = localStorage.getItem("put_products");
      console.log(get);
      if (get !== null) {
        const getData = JSON.parse(get);
        const savedProducts = [...getData];
        savedProducts.push(body);
        localStorage.setItem("put_products", JSON.stringify(savedProducts));
      } else {
        const savedProducts = [];
        savedProducts.push(body);
        localStorage.setItem("put_products", JSON.stringify(savedProducts));
      }
    });
};
export const updateGeneral = async (url, body) => {
  console.log(url);
  await fetch(url, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  }).then(() => console.log("se actualizo"));
};
