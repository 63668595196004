import React from 'react'
import Auth from '../Components/Login/Auth'

function Login() {
    return (
        <div style={{height: "100vh", paddingTop:"10%"}}>
            <Auth></Auth>
        </div>
    )
}

export default Login