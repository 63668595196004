import React, { useEffect, useState } from "react";
import {
  eliminar,
  get,
  post,
  update,
} from "../../PeticionesHTTP/Peticiones";

function UpdateRuleta({ ruleta, setControlPopupUpdate, setRuletas }) {
  const urlProducts = `${process.env.REACT_APP_API}/productos`;
  const [productos, setProductos] = useState();
  const [zonas, setZonas] = useState();
  const [premios, setPremios] = useState();
  const [popupPremios, setPopupPremios] = useState(false);
  const [dataRuleta, setDataRuleta] = useState({
    name: "",
    zona_id: "",
  });

  const handleChange = async (e) => {
    setDataRuleta({
      ...dataRuleta,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickDeleteProduct = (e, product) => {
    e.preventDefault();
    const urlDelete = `${process.env.REACT_APP_API}/listproducts/${product.id}/${product.zona_id}/${product.producto_id}`;
    eliminar(urlDelete);
    setTimeout(() => getData(), 1000);
  };

  const handleReward = (premio) => {
    const urlCreateReward = `${process.env.REACT_APP_API}/listproduct`;
    let bodyProduct = {
      id: ruleta.id,
      cantidad: premio.cantidad_total,
      zona_id: parseInt(ruleta.zona_id),
      producto_id: premio.id,
    };
    console.log(bodyProduct);
    post(urlCreateReward, bodyProduct);
    setPopupPremios(false);
    setTimeout(() => getData(), 1000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_API}/ruleta/${ruleta.id}`;

    const body = {
      name: dataRuleta.name === "" ? ruleta.name : dataRuleta.name,
      zona_id: dataRuleta.zona_id === "" ? ruleta.zona_id : dataRuleta.zona_id,
    };

    await update(url, body);
    setControlPopupUpdate(false)
    setTimeout(() => {
      setRuletas(false);
    }, 1000);
  };
  const getData = () => {
    fetch(`${process.env.REACT_APP_API}/${ruleta.id}/${ruleta.zona_id}`)
      .then((result) => result.json())
      .then((dataResult) => setProductos(dataResult));
  };

  useEffect(async () => {
    setZonas(await get(`${process.env.REACT_APP_API}/zonas`));
    setPremios(await get(`${process.env.REACT_APP_API}/productos`));
    getData();
  }, []);

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <form
        className="Form-create-product"
        style={{ width: "60% !important" }}
        onChange={(e) => handleChange(e)}
        onSubmit={handleSubmit}
      >
        {popupPremios && (
          <div className="popup-list-product">
            <h1>Premios</h1>
            <div className="box-rewards">
              {premios &&
                premios.map((premio) => (
                  <div
                    key={premio.id}
                    name="productos"
                    className="dropdown-products hover-box"
                    style={{ margin: "10px" }}
                    onClick={() => handleReward(premio)}
                  >
                    <div>{premio.name}</div>
                    <i
                      className="fa-solid fa-plus"
                      style={{ color: "black" }}
                    ></i>
                  </div>
                ))}
            </div>
            <div className="buttons-update" style={{ marginTop: "-0.2%" }}>
              <button onClick={() => setPopupPremios(false)}>Cancelar</button>
            </div>
          </div>
        )}

        <h1>Actualizar Ruleta</h1>
        <input name="name" type="text" placeholder={ruleta.name}></input>
        <select name="zona_id" required>
          <option>{ruleta.nombre_zona}</option>
          {zonas &&
            zonas.map((zona) => (
              <option
                key={zona.id}
                value={zona.id}
                hidden={zona.nombre === ruleta.nombre_zona}
              >
                {zona.nombre}
              </option>
            ))}
        </select>

        <div
          name="productos"
          className="dropdown-products"
          onClick={() => setPopupPremios(true)}
        >
          <div>Agregar Premios</div>
          <i className="fa-solid fa-plus" style={{ color: "black" }}></i>
        </div>
        <div
          className={"dropdown-product-content"}
          style={{ marginTop: "15px" }}
        >
          {productos &&
            productos.map((product) => (
              <div
                key={product.producto_id}
                value={product.producto_id}
                className="dropdown-items-update"
              >
                {product.name}
                <button
                  className="delete"
                  style={{ width: "40px" }}
                  onClick={(e) => handleClickDeleteProduct(e, product)}
                >
                  <i className="fa-solid fa-trash-can"></i>
                </button>
              </div>
            ))}
        </div>

        <div className="buttons-update">
          <button type={"submit"}>Actualizar</button>
          <button className="" onClick={() => setControlPopupUpdate(false)}>
            Cancelar
          </button>
        </div>
      </form>
    </div>
  );
}

export default UpdateRuleta;
