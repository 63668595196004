import React, { useContext, useEffect, useState } from "react";
import { eliminar, get } from "../../PeticionesHTTP/Peticiones";
import { ContextBar } from "../../Context/BarContext";
import "./Asesores.css";
import ReportAsesor from "./ReportAsesor";

function ListAserores() {
  const [asesores, setAsesores] = useState();
  const [asesoresUpdate, setAsesoresUpdate] = useState();
  const [controlPopupUpdate, setControlPopupUpdate] = useState(false);
  const { toggle } = useContext(ContextBar);
  const [mostradores, setMostradores] = useState();
  const [dataMostrador, setDataMostrador] = useState();

  const handleClickDelete = async (id) => {
    console.log(id);
    eliminar(`${process.env.REACT_APP_API}/producto/${id}`);
    setTimeout(() => setAsesores(false), 1000);
  };

  const getDataAdviderReport = (id) => {
    console.log(mostradores);
    const dataFilter = mostradores.filter(
      (mostrador) => mostrador.asesor_id === id
    );
    console.log(dataFilter);
    const busqueda = dataFilter.reduce((acc, persona) => {
      acc[persona.municipio_nombre] = ++acc[persona.municipio_nombre] || 1;
      return acc;
    }, {});
    const resultado = Object.keys(busqueda).map((key) => {
      const filtroMunicipio = mostradores.filter(
        (mostrador) => mostrador.municipio_nombre === key
      );
      const premios = filtroMunicipio.reduce((acc, persona) => {
        acc[persona.premio] = ++acc[persona.premio] || 1;
        return acc;
      }, {});

      return { [key]: premios };
    });
    setDataMostrador(resultado);
  };
  //reportes por asesor
  useEffect(async () => {
    if (!mostradores)
      setMostradores(await get(`${process.env.REACT_APP_API}/mostradores`));
    return () => setMostradores(false);
  }, [mostradores]);

  useEffect(async () => {
    if (!asesores)
      setAsesores(await get(`${process.env.REACT_APP_API}/asesores`));
    return () => setAsesores(false);
  }, [asesores]);

  return (
    <div>
      {controlPopupUpdate && (
        <div className={toggle ? "light-box width-80" : "light-box width-100"}>
          <ReportAsesor
            setControlPopupViewReport={setControlPopupUpdate}
            dataMostrador={dataMostrador}
          ></ReportAsesor>
        </div>
      )}
      <div className="list-product">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Telefono</th>
              <th>Zona</th>
              <th>Acciones</th>
            </tr>
          </thead>

          <tbody>
            {asesores &&
              asesores.map((asesor) => {
                
                return<tr key={asesor.id}>
                  <td>{asesor.id}</td>
                  <td>{asesor.nombre.toUpperCase()}</td>
                  <td>{asesor.telefono}</td>
                  <td>{asesor.nombre_zona.toUpperCase()}</td>

                  <td>
                    <div className="colunm-buttons">
                      <button
                        className="delete"
                        onClick={() => handleClickDelete(asesor.id)}
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </button>
                      <button
                        className="edit"
                        onClick={() => {
                          setControlPopupUpdate(true);
                          setAsesoresUpdate(asesor);
                        }}
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </button>
                      <button
                        className="edit"
                        onClick={() => {
                          setControlPopupUpdate(true);
                          setAsesoresUpdate(asesor);
                          getDataAdviderReport(asesor.id);
                        }}
                      >
                        <i className="fa-solid fa-list"></i>
                      </button>
                    </div>
                  </td>
                </tr>;
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ListAserores;
