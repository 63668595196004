import React, { useEffect, useState } from "react";
import { get, post } from "../../PeticionesHTTP/Peticiones";

function CreateAsesor() {
  const [zonas, setZonas] = useState();
  const [dataProduct, setdataProduct] = useState({
    nombre: "",
    telefono: "",
    zona_id: "",
  });

  const urlZonas = `${process.env.REACT_APP_API}/zonas`;
  const handleChange = (e) => {
    setdataProduct({
      ...dataProduct,
      [e.target.name]: e.target.value,
    });
    console.log(dataProduct);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_API}/asesor`;
    const body = {
      nombre: dataProduct.nombre,
      telefono: dataProduct.telefono,
      zona_id: dataProduct.zona_id,
    };
    console.log(body);
    post(url, body);
    e.target.reset();
  };

  useEffect(async () => {
    setZonas(await get(urlZonas));

    return () => {
      setZonas(false);
    };
  }, []);
  console.log(zonas)
  return (
    <div>
      <form className="Form-create-product" onSubmit={handleSubmit}>
        <h1>Crear asesor</h1>

        <input
          name="nombre"
          type="text"
          placeholder="Nombre del Asesor"
          onChange={(e) => handleChange(e)}
        ></input>
        <input
          name="telefono"
          type={"text"}
          placeholder="Telefono"
          onChange={(e) => handleChange(e)}
        ></input>
        <select name="zona_id" onChange={(e) => handleChange(e)}>
          <option>Seleccionar zona</option>
          {zonas &&
            zonas.map((zona) => (
              <option key={zona.id} value={zona.id}>
                {zona.nombre}
              </option>
            ))}
        </select>
        <input type={"submit"} value={"Crear"}></input>
      </form>
    </div>
  );
}

export default CreateAsesor;
