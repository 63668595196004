import React, { useContext, useState } from "react";
import BarLateral from "../Components/BarLateral/BarLateral";
import Header from "../Components/Header/Header";
import LightBox from "../Components/LightBox/LightBox";
import CreateRuleta from "../Components/Ruleta/CreateRuleta";
import { ContextBar } from "../Context/BarContext";

function ViewCreateRuleta() {
  const { toggle } = useContext(ContextBar);
  const [controlPopup, setControlPopup] = useState(false);
  return (
    <div style={{ display: "flex" }}>
      <BarLateral />
      <div
        style={
          toggle
            ? { width: "80%", transition: "1s" }
            : { width: "100%", transition: "1s" }
        }
      >
        <Header />
        <CreateRuleta setControlPopup={setControlPopup} />
        {controlPopup && (
          <div className={toggle ? "light-box width-80" : "light-box width-100"}>
            <LightBox>
              <h1 style={{ color: "black" }}>
                {"¡La Ruleta se creo exitosamente!"}
              </h1>
            </LightBox>
          </div>
        )}
      </div>
    </div>
  );
}

export default ViewCreateRuleta;
