import React, { useEffect, useRef, useState } from "react";
import LogoCorteva from "../../img/LOGOS CORTEVA_Mesa de trabajo 1.png";
import LogoSuper from "../../img/LOGO SUPER GANADERIA_BLANCO.png";
import Marco2 from "../../img/MARCO RULETA2.png";
import Torta from "../../img/TORTA PREMIOS RULETA.png";
import "./Entrada.css";
import { useParams } from "react-router-dom";
import { get } from "../../PeticionesHTTP/Peticiones";

function Entrada({ setControlWelcome, handleChange, setMode }) {
  const { idzona } = useParams();
  const [select, setSelect] = useState("welcome");
  const [municipios, setMunicipios] = useState();
  const [asesores, setAsesores] = useState();

  const handleClickForm = (e) => {
    e.preventDefault();
    setControlWelcome(false);
    setMode("envio");
  };

  useEffect(async () => {
    setMunicipios(
      await get(`${process.env.REACT_APP_API}/municipios/${idzona}`)
    );
  }, []);

  useEffect(async () => {
    setAsesores(
      await get(`${process.env.REACT_APP_API}/asesor&idmunicipio=${idzona}`)
    );
  }, []);

  return (
    <div className="ligth-box-enter">
      <div className="box-left">
        <div className="ruleta-entrada">
          <img src={Marco2}></img>
          <img src={Torta}></img>
          <h1
            onClick={() => {
              setControlWelcome(false);
              setMode("probar");
            }}
          >
            Probar
          </h1>
        </div>
      </div>
      <div className="box-right">
        {select === "welcome" && (
          <div className="welcome">
            <button
              onClick={() => {
                setControlWelcome(false);
                setMode("probar");
              }}
              className={"btn-probar-movil"}
            >
              Probar
            </button>
            <h1 className="titte-entrada">
              La Ruleta <br></br>
              <span className="entrada-title2">
                Navius
                <sup style={{ fontSize: "22px" }}>®</sup>
              </span>
              <br></br>
              <span className="entrada-title3">¡te premia!</span>
            </h1>
            <div className="box-boton">
              <button onClick={() => setSelect("welcome-form")}>Entrar</button>
            </div>
          </div>
        )}
        {select === "welcome-form" && (
          <div style={{ width: "100%" }}>
            <h1 className="title-right">Ingresa tus datos</h1>
            <form
              className="welcome-formulario"
              onSubmit={(e) => handleClickForm(e)}
              onChange={handleChange}
            >
              <label name="nombre">Nombre del Mostrador</label>
              <input
                type="text"
                name="nombre"
                onChange={handleChange}
                required
              />
              <label>Telefono del Mostrador</label>
              <input
                type="text"
                name="telefono"
                onChange={handleChange}
                required
              />
              <label>Almacen</label>
              <input
                type="text"
                name="almacen"
                onChange={handleChange}
                required
              />
              <label>Asesor Corteva</label>
              <select name="asesor_id" required>
                <option>Seleccionar Asesor</option>
                {asesores &&
                  asesores.map((asesor) => (
                    <option key={asesor.id} value={asesor.id}>
                      {asesor.nombre}
                    </option>
                  ))}
              </select>
              <label>Municipio</label>
              <select name="municipio_id" required>
                <option>Seleccionar municipio</option>
                {municipios &&
                  municipios.map((municipio) => (
                    <option key={municipio.id} value={municipio.id}>
                      {municipio.nombre}
                    </option>
                  ))}
              </select>
              <button className="button-form">Registrar</button>
            </form>
          </div>
        )}
        <div className="logos-right">
          <img src={LogoCorteva} className="logo-bajo-corteva"></img>
          <img src={LogoSuper} className="logo-bajo"></img>
        </div>
      </div>
    </div>
  );
}

export default Entrada;
