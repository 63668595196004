import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { getProducts, post, update } from "../PeticionesHTTP/Peticiones";
import AudioClick from "../Resources/Audio/Mouse Click - Sound Effect.mp3";
import AudioRuleta from "../Resources/Audio/roulette sound effect 2020.mp3";
import AudioGritos from "../Resources/Audio/Clapping hands Gritos y Ovacion.mp3";
import Marco from "../img/MARCO RULETA.png";
import NaviusLogo from "../img/NAVIUS_Mesa de trabajo 1.png";

import Indicador from "../img/INDICADOR .png";
import BotonSpin from "../img/BOTON (1).png";
import ReactHowler from "react-howler/lib/ReactHowler";

import Entrada from "../Components/Entrada/Entrada";

function Ruleta() {
  const elementRef = useRef(null);
  const { id, idzona } = useParams();
  const [ClickRuleta, setClickRuleta] = useState(false);
  const [girarRuleta, setGirarRuleta] = useState(false);
  const [controlWelcome, setControlWelcome] = useState(true);
  const [gritos, setGritos] = useState(false);
  const [productos, setProductos] = useState();
  const [circlePerimeter, setCirclePerimeter] = useState();
  const [calculeWidth, setCalculeWidth] = useState();
  const [calculeDeg, setCalculeDeg] = useState();
  const [reward, setReward] = useState();
  const [imagenReward, setImagenReward] = useState();
  const [controlPopup, setControlPopup] = useState(false);
  const [text, setText] = useState();
  const [mode, setMode] = useState("prueba");
  const [giros, setGiros] = useState(0);
  const [dataMostrador, setDataMostrador] = useState({
    nombre: "",
    telefono: "",
    premio: "",
    municipio_id: "",
    asesor_id: "",
    almacen: "",
  });
  const [giro, setGiro] = useState(90);

  const handleClick = () => {
    setGirarRuleta(true);
    setGiros(giros + 1);
    setTimeout(() => {
      setGirarRuleta(false);
      setGritos(true);
      setTimeout(() => setGritos(false), 5000);
    }, 5000);

    let arrayBottoms = [];
    setGiro(
      Math.ceil(Math.random() * productos.length * 100) *
        Math.ceil(350 / productos.length)
    );
    elementRef.current.style.transform = "rotate(" + giro + "deg)";

    setTimeout(() => {
      for (let i = 0; i < elementRef.current.childNodes.length; i++) {
        arrayBottoms.push(
          elementRef.current.childNodes[i].getBoundingClientRect().left
        );
        console.log(
          elementRef.current.childNodes[i].getAttribute("name") +
            ": " +
            elementRef.current.childNodes[i].getBoundingClientRect().left
        );
      }
      let min = Math.max(...arrayBottoms);
      console.log("minimo:" + " " + min);
      for (let i = 0; i < elementRef.current.childNodes.length; i++) {
        if (
          elementRef.current.childNodes[i].getBoundingClientRect().left === min
        ) {
          setReward(elementRef.current.childNodes[i].getAttribute("name"));
          setImagenReward(elementRef.current.childNodes[i].getAttribute("img"));

          if (
            mode === "envio" &&
            parseInt(
              elementRef.current.childNodes[i].getAttribute("cantidad")
            ) > 1
          ) {
            const body = {
              id: elementRef.current.childNodes[i].getAttribute("id"),
              name: elementRef.current.childNodes[i].getAttribute("name"),
              cantidad_total:
                elementRef.current.childNodes[i].getAttribute("cantidad") - 1,
              cantidad_lista:
                elementRef.current.childNodes[i].getAttribute("cantidad") - 1,
              descripcion:
                elementRef.current.childNodes[i].getAttribute("descripcion"),
              imagen: elementRef.current.childNodes[i].getAttribute("img"),
            };
            update(
              `${
                process.env.REACT_APP_API
              }/producto/${elementRef.current.childNodes[i].getAttribute(
                "id"
              )}`,
              body
            );

            const bodyMostrador = {
              nombre: dataMostrador.nombre,
              telefono: dataMostrador.telefono,
              premio: elementRef.current.childNodes[i].getAttribute("name"),
              municipio_id: dataMostrador.municipio_id,
              almacen: dataMostrador.almacen,
              asesor_id: dataMostrador.asesor_id,
            };
            post(`${process.env.REACT_APP_API}/mostrador`, bodyMostrador).then(
              () => setTimeout(() => (window.location = ""), 6000)
            );
            setControlPopup(true);
            setTimeout(() => setControlPopup(false), 6000);
          } else if (
            mode === "envio" &&
            parseInt(
              elementRef.current.childNodes[i].getAttribute("cantidad")
            ) === 0
          ) {
            setGiros(0);
          } else if (mode === "probar") {
            setGiros(0);
          }
        }
      }
      console.log(min);
    }, 5100);
  };

  const handleChange = (e) => {
    setDataMostrador({
      ...dataMostrador,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(async () => {
    if (!productos) {
      const products = await getProducts(
        `${process.env.REACT_APP_API}/${id}/${idzona}`
      );
      setProductos(products);
    }

    setCirclePerimeter(
      Math.ceil(
        2 * Math.PI * (elementRef.current.getBoundingClientRect().width / 2)
      )
    );
    if (productos) setCalculeWidth(circlePerimeter / productos.length);
    if (productos)
      setGiro(
        Math.ceil(Math.random() * productos.length * 100) *
          Math.ceil(350 / productos.length)
      );
    if (productos) setCalculeDeg(Math.ceil(360 / productos.length));
    setText(calculeWidth / 2);
  }, [circlePerimeter, calculeWidth, calculeDeg, text]);

  useEffect(async () => {
    await getProducts(`${process.env.REACT_APP_API}/${id}/${idzona}`).then(
      () => {
        const get = localStorage.getItem("put_products");
        if (get !== null) {
          const getData = JSON.parse(get);
          const savedProducts = [...getData];

          savedProducts.map(async (product) => {
            const body = {
              cantidad_total: product.cantidad_total,
              cantidad_lista: product.cantidad_lista,
              descripcion: product.description,
            };
            setTimeout(async () => {});
          });
        }
      }
    );
  }, []);

  return (
    <div style={{ width: "100vw", height: "100vh", paddingTop: "2%" }}>
      <ReactHowler src={AudioClick} playing={ClickRuleta} />
      <ReactHowler src={AudioRuleta} playing={girarRuleta} />
      <ReactHowler src={AudioGritos} playing={gritos} />
      {mode === "probar" && (
        <button
          className="button-form probar"
          onClick={() => (window.location = "")}
        >
          Regresar
        </button>
      )}
      {controlWelcome && (
        <Entrada
          setControlWelcome={setControlWelcome}
          handleChange={handleChange}
          dataMostrador={dataMostrador}
          setMode={setMode}
        />
      )}
      {controlPopup && (
        <div className="ligth-box-reward">
          <h1 className="title-reward">¡GANASTE!</h1>
          <br></br>
          <div className="circle-reward">
            <img src={imagenReward}></img>
          </div>
          <h1 className="subttitle-reward">{reward}</h1>
        </div>
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
          position: "absolute",
        }}
      >
        <img src={NaviusLogo} className="nav-logo"></img>
      </div>
      {productos ? (
        <div className="Ruler" style={{ position: "relative", zIndex: "99" }}>
          <img className="arrow-l" src={Indicador}></img>
          <img
            id="spin"
            src={BotonSpin}
            onClick={giros === 0 ? handleClick : (e) => e.preventDefault()}
          ></img>
          <div id="ruleta" className="" ref={elementRef}>
            <img src={Marco} className="marco"></img>
            {productos.map((producto, index) => {
              const deg = calculeDeg * index;
              const name = producto.name.split(" ").join("<br></br>");

              return (
                <div
                  img={producto.imagen}
                  key={producto.producto_id}
                  id={producto.producto_id}
                  cantidad={producto.cantidad_total}
                  name={producto.name}
                  descripcion={producto.descripcion}
                  style={{
                    fontSize: `${productos.length > 9 ? "0.9vw" : "1.2vw"}`,
                    width: calculeWidth + "px",
                    left: "calc(" + "50% - " + text + "px )",
                    transform: "rotate(" + deg + "deg )",
                    clipPath: `polygon(0 ${0}%, 50% 100%, ${
                      productos.length > 3
                        ? productos.length > 8
                          ? productos.length > 9
                            ? productos.length > 10
                              ? productos.length > 11
                                ? 210 - productos.length * 8
                                : 210 - productos.length * 9
                              : 210 - productos.length * 10
                            : 210 - productos.length * 11
                          : 210 - productos.length * 14
                        : 210
                    }% ${0}%)`,
                  }}
                  className={`color-${index}`}
                >
                  <p className="rota">
                    <span dangerouslySetInnerHTML={{ __html: name }}></span>
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div style={{ width: "100px", margin: "auto", textAlign: "center" }}>
          <progress></progress>
          <br></br>
          Loading...
        </div>
      )}
    </div>
  );
}

export default Ruleta;
