import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ContextBar } from "../../Context/BarContext";
import { eliminar, get } from "../../PeticionesHTTP/Peticiones";
import LightBox from "../LightBox/LightBox";
import "./Ruleta.css";
import UpdateRuleta from "./UpdateRuleta";

function ListaRuletas() {
  const [ruletas, setRuletas] = useState();
  const [ruleta, setRuleta] = useState();
  const [controlPopup, setControlPopup] = useState(false);
  const [controlPopupUpdate, setControlPopupUpdate] = useState(false);
  const { toggle } = useContext(ContextBar);

  const handleClickDelete = async (id) => {
    eliminar(`${process.env.REACT_APP_API}/ruleta/${id}`).then(() => {
      setControlPopup(true);
      setTimeout(() => setControlPopup(false), 3000);
    });
    setTimeout(() => {
      setRuletas(false);
    }, 1000);
  };

  useEffect(async () => {
    if (!ruletas) setRuletas(await get(`${process.env.REACT_APP_API}/ruletas`));
  }, [ruletas]);
  console.log(ruletas);
  return (
    <div>
      {controlPopupUpdate && (
        <div className={toggle ? "light-box width-80" : "light-box width-100"}>
          <UpdateRuleta
            setRuletas={setRuletas}
            setControlPopupUpdate={setControlPopupUpdate}
            ruleta={ruleta}
          ></UpdateRuleta>
        </div>
      )}
      {controlPopup && (
        <div className={toggle ? "light-box width-80" : "light-box width-100"}>
          <LightBox>
            <i className="fa-solid fa-circle-check"></i>
            <h1 style={{ color: "black" }}>La ruleta se elimino con exito</h1>
          </LightBox>
        </div>
      )}
      <div className="table-list-ruleta">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Zona</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {ruletas &&
              ruletas.map((ruleta) => (
                <tr key={ruleta.id} hidden={ruleta.name === "prueba"}>
                  <td>{ruleta.id}</td>
                  <td>{ruleta.name}</td>

                  <td>{ruleta.nombre_zona}</td>

                  <td className="buttons-table-ruletas">
                    <button
                      className="delete"
                      onClick={() => handleClickDelete(ruleta.id)}
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </button>
                    <button
                      className="edit"
                      onClick={() => {
                        setRuleta(ruleta);
                        setControlPopupUpdate(true);
                      }}
                    >
                      <i className="fa-solid fa-pen-to-square"></i>
                    </button>
                    <button className="view">
                      <Link to={`/ruleta&${ruleta.name}=${ruleta.id}&${ruleta.nombre_zona}=${ruleta.zona_id}`}>
                        <i className="fa-solid fa-eye"></i>
                      </Link>
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ListaRuletas;
