import React, { useContext, useEffect, useState } from "react";
import { ContextBar } from "../../Context/BarContext";
import { eliminar, get } from "../../PeticionesHTTP/Peticiones";
import UpdateProduct from "./UpdateProduct";

function Productos() {
  const [productos, setProductos] = useState();
  const [productUpdate, setProductUpdate] = useState();
  const [controlPopupUpdate, setControlPopupUpdate] = useState(false);
  const { toggle } = useContext(ContextBar);

  const handleClickDelete = async (id) => {
    console.log(id);
    eliminar(`${process.env.REACT_APP_API}/producto/${id}`);
    setTimeout(() => setProductos(false), 1000);
  };

  useEffect(async () => {
    if (!productos)
      setProductos(await get(`${process.env.REACT_APP_API}/productos`));
    return () => setProductos(false);
  }, [productos]);

  return (
    <div>
      {controlPopupUpdate && (
        <div className={toggle ? "light-box width-80" : "light-box width-100"}>
          <UpdateProduct
            setProductos={setProductos}
            data={productUpdate}
            setControlPopupUpdate={setControlPopupUpdate}
          ></UpdateProduct>
        </div>
      )}
      <div className="list-product">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Cantidad</th>
              <th>Imagen</th>
              <th>Acciones</th>
            </tr>
          </thead>

          <tbody>
            {productos &&
              productos.map((producto) => (
                <tr key={producto.id}>
                  <td>{producto.id}</td>
                  <td>{producto.name}</td>
                  <td>{producto.cantidad_total}</td>
                  <td style={{ width: "200px" }}>
                    <img src={producto.imagen} className="image-product"></img>
                  </td>
                  <td>
                    <div className="colunm-buttons">
                      <button
                        className="delete"
                        onClick={() => handleClickDelete(producto.id)}
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </button>
                      <button
                        className="edit"
                        onClick={() => {
                          setControlPopupUpdate(true);
                          setProductUpdate(producto);
                        }}
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Productos;
