import React, { useContext } from 'react'
import BarLateral from '../Components/BarLateral/BarLateral'
import Header from '../Components/Header/Header'
import { ContextBar } from '../Context/BarContext'

function Home() {
  const { toggle } = useContext(ContextBar)
  return (
    <div style={{display: "flex"}}>
      <BarLateral />
      <div style={toggle ? { width: "80%", transition: "1s" } : { width: "100%", transition: "1s" }}>
        <Header></Header>
      </div>
      
    </div>
  )
}

export default Home