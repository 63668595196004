import React, { useContext, useState } from "react";
import "./BarLateral.css";
import { ContextBar } from "../../Context/BarContext";
import LogoCroteva from "../../Resources/img/logo-corteva.jpeg";
import { Link } from "react-router-dom";
import { ContextAuth } from "../../Context/AuthContext";

function BarLateral() {
  const { toggle, dropDownSelect, setDropDownSelect } = useContext(ContextBar);
  const { dataUser } = useContext(ContextAuth);
  
  return (
    <div className={toggle ? "bar-lateral" : "bar-lateral noneWidth"}>
      <div className="user-information">
        <img src={LogoCroteva} className="logo"></img>
        <h4>{dataUser && dataUser.user.name ? dataUser.user.name : "Cargando..."}</h4>
      </div>
      <div className="list-bar">
        <ul className="list">
          <li className="items" onClick={() => setDropDownSelect("ruletas")}>
            <div className="dropdown">
              Ruletas
              <i className="fa-solid fa-circle-arrow-down"></i>
            </div>
            <div
              className={
                dropDownSelect === "ruletas" ? "dropdown-content" : "noneHeight"
              }
            >
              <ul>
                <li>
                  <Link to={"/ruletas"}>Ver lista de Ruletas</Link>
                </li>
                <li>
                  <Link to={"/ruletas/crear"}>Crear Ruleta</Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="items" onClick={() => setDropDownSelect("productos")}>
            <div className="dropdown">
              Premios
              <i className="fa-solid fa-circle-arrow-down"></i>
            </div>
            <div
              className={
                dropDownSelect === "productos"
                  ? "dropdown-content"
                  : "noneHeight"
              }
            >
              <ul>
                <li>
                  <Link to={"/productos"}>Ver lista de Premios</Link>
                </li>
                <li>
                  <Link to={"/productos/crear"}>crear premio</Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="items" onClick={() => setDropDownSelect("asesores")}>
            <div className="dropdown">
              Asesores
              <i className="fa-solid fa-circle-arrow-down"></i>
            </div>
            <div
              className={
                dropDownSelect === "asesores"
                  ? "dropdown-content"
                  : "noneHeight"
              }
            >
              <ul>
                <li>
                  <Link to={"/asesores"}>Lista de Asesores</Link>
                </li>
                <li>
                  <Link to={"/asesores/crear"}>Crear Asesor</Link>
                </li>
              </ul>
            </div>
          </li>
          <li
            className="items"
            onClick={() => setDropDownSelect("mostradores")}
          >
            <div className="dropdown">
              Mostradores
              <i className="fa-solid fa-circle-arrow-down"></i>
            </div>
            <div
              className={
                dropDownSelect === "mostradores"
                  ? "dropdown-content"
                  : "noneHeight"
              }
            >
              <ul>
                <li>
                  <Link to={"/mostradores"}>Lista de Mostradores</Link>
                </li>
              </ul>
            </div>
          </li>
          <li
            className="items"
            onClick={() => setDropDownSelect("configuracion")}
          >
            <div className="dropdown">
              Configuraciones
              <i className="fa-solid fa-circle-arrow-down"></i>
            </div>
            <div
              className={
                dropDownSelect === "configuracion"
                  ? "dropdown-content"
                  : "noneHeight"
              }
            >
              <ul>
                <li>
                  <Link to={"/"}>Configurar usuario</Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default BarLateral;
