import React, { useEffect, useState } from "react";
import { get, post, postWithResult } from "../../PeticionesHTTP/Peticiones";

function CreateRuleta(props) {
  const { setControlPopup } = props;
  const urlZonas = `${process.env.REACT_APP_API}/zonas`;
  const urlProducts = `${process.env.REACT_APP_API}/productos`;
  const urlListProducts = `${process.env.REACT_APP_API}/listproduct`;
  const [zonas, setZonas] = useState();
  const [alert, setAlert] = useState(false);
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [productos, setProductos] = useState();
  const [dataRuleta, setDataRuleta] = useState({
    name: "",
    zona_id: "",
  });

  const handleChange = async (e) => {
    setDataRuleta({
      ...dataRuleta,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_API}/ruleta`;

    const body = {
      name: dataRuleta.name,
      zona_id: dataRuleta.zona_id,
    };

    if (Object.keys(dataRuleta).length >= 5 && dataRuleta.zona_id !== "") {
      await postWithResult(url, body).then((r) => {
        setControlPopup(true);
        console.log(r);
        productos.map((product) => {
          if (dataRuleta[product.name] === "on") {
            console.log();
            let bodyProduct = {
              id: r.id,
              cantidad: product.cantidad_total,
              zona_id: parseInt(r.zona_id),
              producto_id: product.id,
            };
            post(urlListProducts, bodyProduct);
          }
        });
        setTimeout(() => setControlPopup(false), 2000);
        setToggleDropdown(false);
        e.target.reset();
      });
    } else {
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 5000);
    }
  };

  const handleCheck = (e) => {
    setDataRuleta({
      ...dataRuleta,
      [e.target.name]: e.target.check,
    });
  };
  useEffect(async () => {
    setZonas(await get(urlZonas));
    setProductos(await get(urlProducts));
    return () => {
      setZonas(false);
      setProductos(false);
    };
  }, []);

  return (
    <div>
      <form
        className="Form-create-product"
        onChange={(e) => handleChange(e)}
        onSubmit={handleSubmit}
      >
        <h1>Crear Ruleta</h1>
        <input
          name="name"
          type="text"
          placeholder="Nombre del producto"
          required
        ></input>
        <select name="zona_id" required>
          <option>Seleccione Zona</option>
          {zonas &&
            zonas.map((zona) => (
              <option key={zona.id} value={zona.id}>
                {zona.nombre}
              </option>
            ))}
        </select>
        
        <div
          name="productos"
          className="dropdown-products"
          onClick={() => setToggleDropdown(!toggleDropdown)}
        >
          <div>Selecciona Premios</div>
          <i className="fa-solid fa-angle-down" style={{ color: "black" }}></i>
        </div>
        <div
          className={toggleDropdown ? "dropdown-product-content" : "noneHeight"}
        >
          {productos &&
            productos.map((product) => (
              <div
                key={product.id}
                value={product.id}
                className="dropdown-items"
                onChange={handleCheck}
              >
                <input type={"checkbox"} name={product.name}></input>{" "}
                {product.name}
              </div>
            ))}
        </div>
        {alert && (
          <span className="alert-danger">
            Error: Debes Seleccionar una zona ó seleccionar mas de 3 productos
          </span>
        )}

        <input type={"submit"}></input>
      </form>
    </div>
  );
}

export default CreateRuleta;
