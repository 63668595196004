import React, { useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { ContextBar } from "../Context/BarContext";
import { eliminar, get } from "../PeticionesHTTP/Peticiones";
import "./Mostradores.css";

function Mostradores() {
  const [mostradores, setMostradores] = useState(false);
  const [TablaMostradores, setTablaMostradores] = useState([]);
  const [mostradorUpdate, setMostradorUpdate] = useState();
  const [controlPopupUpdate, setControlPopupUpdate] = useState(false);
  const [busqueda, setBusqueda] = useState("");
  const { toggle } = useContext(ContextBar);
  const [csvReport, setCsvReport] = useState();
  const headers = [
    { label: "nombre", key: "nombre" },
    { label: "telefono", key: "telefono" },
    { label: "premio", key: "premio" },
    { label: "asesor", key: "asesor_nombre" },
    { label: "municipio", key: "municipio_nombre" },
    { label: "almacen", key: "almacen" },
    { label: "fecha de giro", key: "date" },
    { label: "zona", key: "zona_nombre" },
  ];

  const handleClickDelete = async (id) => {
    eliminar(`${process.env.REACT_APP_API}/mostrador/${id}`);
    setTimeout(() => setMostradores(false), 1000);
  };

  const handleOnchange = (e) => {
    setBusqueda(e.target.value);
    filtrar(busqueda);
  };

  const filtrar = (buscar) => {
    let resultSearch = TablaMostradores.filter(
      (element) =>
        (element.nombre
          .toString()
          .toLowerCase()
          .includes(buscar.toLowerCase()) ||
          element.asesor_nombre
            .toString()
            .toLowerCase()
            .includes(buscar.toLowerCase()) ||
          element.asesor_nombre
            .split(" ")[1]
            .toString()
            .toLowerCase()
            .includes(buscar.toLowerCase()) ||
          element.municipio_nombre
            .toString()
            .toLowerCase()
            .includes(buscar.toLowerCase()) ||
          element.zona_nombre
            .toString()
            .toLowerCase()
            .includes(buscar.toLowerCase()) ||
          element.premio
            .toString()
            .toLowerCase()
            .includes(buscar.toLowerCase())) &&
        element
    );
    setMostradores(resultSearch);
    setCsvReport(false);
  };
  console.log(mostradores);
  useEffect(async () => {
    if (!mostradores) {
      const result = await get(`${process.env.REACT_APP_API}/mostradores`);
      const change = await result.map((element) => {
        element.date = new Date(
          element.created_at.split(" ").join("T") + "Z"
        ).toLocaleString();
        return element;
      });
      const orderByDate = change.sort(function (a, b) {
        if (a.created_at > b.created_at) {
          return 1;
        }
        if (a.created_at < b.created_at) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });

      setMostradores(orderByDate);
      setTablaMostradores(orderByDate);
    }

    return () => setMostradores(false);
  }, [mostradores]);

  useEffect(() => {
    if (mostradores && !csvReport)
      setCsvReport({
        data: mostradores,
        headers: headers,
        filename:
          "ganadores_" + new Date().toLocaleDateString("en-us") + ".csv",
      });
  }, [mostradores, busqueda]);

  return (
    <div>
      <div className="header-list-mostradores">
        {csvReport && (
          <CSVLink {...csvReport} className="buttom-download">
            <button className="boton-to-excel">Dercargar como csv</button>
          </CSVLink>
        )}
        <div className="input-search">
          <input
            type={"text"}
            value={busqueda}
            placeholder="Buscar"
            onChange={(e) => handleOnchange(e)}
          ></input>
          <button className="btn-search">
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
      </div>
      {controlPopupUpdate && (
        <div className={toggle ? "light-box width-80" : "light-box width-100"}>
          asdasda
        </div>
      )}
      <div className="list-product">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Telefono</th>
              <th>Premio</th>
              <th>Asesor</th>
              <th>Zona</th>
              <th>Municipio</th>
              <th>Almacen</th>
              <th>Fecha de giro</th>
              <th>Acciones</th>
            </tr>
          </thead>

          <tbody>
            {mostradores &&
              mostradores.map((mostrador) => (
                <tr key={mostrador.id}>
                  <td>{mostrador.id}</td>
                  <td>{mostrador.nombre.toUpperCase()}</td>
                  <td>{mostrador.telefono}</td>
                  <td>{mostrador.premio}</td>
                  <td>{mostrador.asesor_nombre.toUpperCase()}</td>
                  <td>{mostrador.zona_nombre.toUpperCase()}</td>
                  <td>{mostrador.municipio_nombre.toUpperCase()}</td>
                  <td>{mostrador.almacen.toUpperCase()}</td>
                  <td>{mostrador.updated_at.split(" ")[0]}</td>
                  <td>
                    <div className="colunm-buttons">
                      <button
                        className="delete"
                        onClick={() => handleClickDelete(mostrador.id)}
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </button>
                      <button
                        className="edit"
                        onClick={() => {
                          setControlPopupUpdate(true);
                          setMostradorUpdate(mostrador);
                        }}
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Mostradores;
