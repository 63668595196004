import React, { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";

function ReportAsesor({ setControlPopupViewReport, dataMostrador }) {
  console.log(dataMostrador);
  const tableRef = useRef(null);
  const [statusButtonExport, setStatusButtonExport] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setStatusButtonExport(true);
    }, 1500);
    return () => {
      setStatusButtonExport(false);
    };
  }, []);

  return (
    <div className="popup">
      <div className="list-product">
        <table ref={tableRef}>
          <thead>
            <tr>
              <th>#</th>
              <th>Municipio</th>
              <th>Premio</th>
              <th>Cantidad</th>
            </tr>
          </thead>

          <tbody>
            {dataMostrador &&
              dataMostrador.map((asesor, index) => (
                <tr key={index}>
                  <td
                    style={{ border: "1px solid rgba(128, 128, 128, 0.479)" }}
                  >
                    {index}
                  </td>
                  <td>
                    {Object.keys(asesor).map((key) => {
                      return key;
                    })}
                  </td>
                  <td>
                    {Object.keys(asesor).map((key) =>
                      Object.keys(asesor[key]).map((premio, indexP) => (
                        <div className="items-report" key={indexP}>
                          {premio}
                        </div>
                      ))
                    )}
                  </td>
                  <td>
                    {Object.keys(asesor).map((key) =>
                      Object.keys(asesor[key]).map((premio, indexP) => (
                        <div className="items-report" key={indexP}>
                          {asesor[key][premio]}
                        </div>
                      ))
                    )}
                  </td>

                  <td></td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="buttons-update">
        {statusButtonExport && (
          <DownloadTableExcel
            filename={`report-${new Date().toLocaleString("en-US")}`}
            sheet="users"
            currentTableRef={tableRef.current}
           
          >
            <button  style={{width: "300px", minWidth: "100px"}}> Export excel </button>
          </DownloadTableExcel>
        )}
        
        <button className="" onClick={() => setControlPopupViewReport(false)}>
          Cancelar
        </button>
      </div>
    </div>
  );
}

export default ReportAsesor;
