import React, { useContext } from 'react'
import BarLateral from '../Components/BarLateral/BarLateral'
import Header from '../Components/Header/Header'
import Productos from '../Components/Productos/Productos'
import { ContextBar } from '../Context/BarContext'

function ViewProductos() {
  const { toggle } = useContext(ContextBar)
  return (
    <div style={{ display: "flex" }}>
      <BarLateral />
      <div style={toggle ? { width: "80%", transition: "1s" } : { width: "100%", transition: "1s" }}>
        <Header />
        <Productos></Productos>
      </div>
    </div>
  )
}

export default ViewProductos