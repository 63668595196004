import React, { createContext, useState } from 'react'

export const ContextBar = createContext();

function BarContext({ children }) {
    const [toggle, setToggle] = useState(false)
    const [dropDownSelect, setDropDownSelect] = useState()

    return (
        <ContextBar.Provider value={ {toggle, setToggle, dropDownSelect, setDropDownSelect} }>
            {children}
        </ContextBar.Provider>
    )
}

export default BarContext