import React, { useContext, useState } from "react";
import { ContextAuth } from "../../Context/AuthContext";
import { postWithResult } from "../../PeticionesHTTP/Peticiones";
import "./Auth.css";

function Auth() {
  const { setIsAuthenticate, setDataUser } = useContext(ContextAuth);

  const [credentials, setCredentials] = useState({
    password: "",
    email: "",
  });
  const handleChange = async (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
    console.log(credentials);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(credentials);
    const resultToken = await postWithResult(
      `${process.env.REACT_APP_API}/login`,
      credentials
    );
    localStorage.setItem("token", resultToken.token);

    await postWithResult(`${process.env.REACT_APP_API}/user`, resultToken).then(
      (result) => {
        console.log(result);
        if (result.status === "Token is Expired") {
          setIsAuthenticate(false);
        }
        if (result.status === "Authorization Token not found") {
          setIsAuthenticate(false);
        } else {
          setIsAuthenticate(true);
          setDataUser(result);
          window.location.pathname = "/";
        }
      }
    );
  };

  return (
    <div className="login">
      <h1>Login</h1>
      <form onSubmit={(e) => handleSubmit(e)} onChange={(e) => handleChange(e)}>
        <input name="email" type={"email"} placeholder={"Email"}></input>
        <input
          name="password"
          type={"password"}
          placeholder={"Password"}
        ></input>
        <input type={"submit"}></input>
      </form>
    </div>
  );
}

export default Auth;
