import React, { createContext, useState } from 'react'

export const ContextAuth = createContext();

function AuthContext({ children }) {
    
    const [token, setToken] = useState()
    const [isAuthenticate, setIsAuthenticate] = useState()
    const [dataUser, setDataUser] = useState()
    return (
        <ContextAuth.Provider value={{token, setToken, isAuthenticate, setIsAuthenticate,dataUser, setDataUser}}>
            {children}
        </ContextAuth.Provider>
    )
}

export default AuthContext