import React, { useContext, useEffect } from "react";
import { ContextBar } from "../../Context/BarContext";
import "./Header.css";

function Header() {
  const { toggle, setToggle, setDropDownSelect } = useContext(ContextBar);
  const [isReadyForInstall, setIsReadyForInstall] = React.useState(false);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (event) => {
      // Prevent the mini-infobar from appearing on mobile.
      event.preventDefault();
      console.log("👍", "beforeinstallprompt", event);
      // Stash the event so it can be triggered later.
      window.deferredPrompt = event;
      // Remove the 'hidden' class from the install button container.
      setIsReadyForInstall(true);
    });
  }, []);

  const handleClick = () => {
    setDropDownSelect("");
    setToggle(!toggle);
  };
  async function downloadApp() {
    console.log("👍", "butInstall-clicked");
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
      // The deferred prompt isn't available.
      console.log("oops, no prompt event guardado en window");
      return;
    }
    // Show the install prompt.
    promptEvent.prompt();
    // Log the result
    const result = await promptEvent.userChoice;
    console.log("👍", "userChoice", result);
    // Reset the deferred prompt variable, since
    // prompt() can only be called once.
    window.deferredPrompt = null;
    // Hide the install button.
    setIsReadyForInstall(false);
  }
  return (
    <div style={{ width: "100%", position: "sticky", top: "0" }}>
      <nav className="header">
        <ul
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button className="hamburguer" onClick={() => handleClick()}>
            <i className="fa-solid fa-bars"></i>
          </button>
          {isReadyForInstall && (
            <button className="buttom-download" onClick={() => downloadApp()}>
              Descargar App
            </button>
          )}
        </ul>
      </nav>
    </div>
  );
}

export default Header;
