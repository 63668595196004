import React, { useState } from "react";
import { updateGeneral } from "../../PeticionesHTTP/Peticiones";

function UpdateProduct({ data, setControlPopupUpdate, setProductos }) {
  const [dataProduct, setdataProduct] = useState({
    name: "",
    cantidad_total: "",
    cantidad_lista: "",
    descripcion: "",
    image: "",
  });
  console.log(data);
  const handleChange = (e) => {
    setdataProduct({
      ...dataProduct,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_API}/producto/${data.id}`;
    const body = {
      name: dataProduct.name === "" ? data.name : dataProduct.name,
      cantidad_total:
        dataProduct.cantidad_total === ""
          ? data.cantidad_total
          : dataProduct.cantidad_total,
      cantidad_lista:
        dataProduct.cantidad_total === ""
          ? data.cantidad_total
          : dataProduct.cantidad_total,
      descripcion:
        dataProduct.descripcion === ""
          ? data.descripcion
          : dataProduct.descripcion,
      imagen: dataProduct.descripcion === "" ? data.imagen : dataProduct.imagen,
    };

    updateGeneral(url, body);
    setControlPopupUpdate(false);
    e.target.reset();
    setTimeout(() => setProductos(false), 1000);
  };

  return (
    <div style={{ width: "100%" }}>
      <form
        className="Form-create-product"
        onChange={(e) => handleChange(e)}
        onSubmit={handleSubmit}
      >
        <h1>Actualizar producto</h1>
        <input name="name" type="text" placeholder={data.name}></input>
        <input
          name="cantidad_total"
          type={"number"}
          placeholder={data.cantidad_total}
        ></input>
        <textarea
          name="descripcion"
          type={"text"}
          placeholder={data.descripcion}
        ></textarea>
        <div className="buttons-update">
          <button type={"submit"}>Actualizar</button>
          <button className="" onClick={() => setControlPopupUpdate(false)}>
            Cancelar
          </button>
        </div>
      </form>
    </div>
  );
}

export default UpdateProduct;
