import React from "react";
import { Link } from "react-router-dom";
import imgLogo from "../img/ICONO_RULETA_256X256.png";
import NaviusLogo from "../img/NAVIUS_Mesa de trabajo 1.png";

function ViewsDashboardRuletas() {
  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
          position: "absolute",
        }}
      >
        <img src={NaviusLogo} className="nav-logo"></img>
      </div>
      <div className="dashboard-ruletas">
        <div className={"items-dashboard"}>
          <Link
            to={"/ruleta&RULETA%20CAQUETA%202024=17&CAQUETA=2"}
            style={{
              width: "100%",
              height: "100%",

              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              lineHeight: "150%"
            }}
          >
            <img src={imgLogo} className={"logo-ruleta"} />
            <h1>CAQUETA 2024</h1>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ViewsDashboardRuletas;
