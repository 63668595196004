import React, { useContext, useEffect } from "react";
import { ContextAuth } from "../../Context/AuthContext";
import { postWithResult } from "../../PeticionesHTTP/Peticiones";

function Loading() {
  const { token, setToken, isAuthenticate, setIsAuthenticate, setDataUser } =
    useContext(ContextAuth);

  useEffect(async () => {
    const tokenLocalstorage = localStorage.getItem("token");

    if (tokenLocalstorage !== null) {
      setToken(tokenLocalstorage);
      if (token) {
        await postWithResult(`${process.env.REACT_APP_API}/user`, {
          token: token,
        })
          .then((result) => {
            console.log(token, result);
            if (
              result.status === "Authorization Token not found" ||
              result.status === "Token is Expired" ||
              result.status === "Token is Invalid"
            ) {
              setIsAuthenticate(false);
            } else {
              setIsAuthenticate(true);
              setDataUser(result);
            }
          })
          .catch(() => {
            setIsAuthenticate(false);
          });
      }
    } else {
      localStorage.setItem("token", "undefined");
      //window.location = "/login";
    }

    return () => setIsAuthenticate(false);
  }, [token]);

  return <div></div>;
}

export default Loading;
